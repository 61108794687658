<template>
  <v-container>
    <v-card class="elevation-3">
      <v-card-title class="custom-bg">
        <span class="headline white--text">Carga de Archivos</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="isFormValid" @submit.prevent="uploadFile" enctype="multipart/form-data">
          <v-text-field
              v-model="folio"
              label="Folio"
              @input="fetchUploadedFiles"
              :rules="[rules.required]"
              required
          ></v-text-field>

          <v-select
              v-model="etapa"
              :items="etapas"
              label="Etapa"
              required
          ></v-select>

          <v-file-input
              v-model="archivo"
              label="Archivo"
              prepend-icon="mdi-paperclip"
              required
              @change="onFileChange"
          ></v-file-input>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="resetForm">
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
            <v-btn text type="submit">
              <v-icon left>mdi-content-save</v-icon> Subir Archivo
            </v-btn>
          </v-card-actions>

          <v-alert
              v-if="message"
              :type="messageType"
              class="mt-4"
              dismissible
          >
            {{ message }}
          </v-alert>
        </v-form>

        <!-- Indicador de carga -->
        <v-progress-linear
            v-if="loading"
            :value="uploadProgress"
            height="10"
            color="primary"
            class="mt-4"
        ></v-progress-linear>

        <!-- Tabla de archivos cargados -->
        <v-card-subtitle class="mt-4">Archivos Cargados</v-card-subtitle>
        <v-data-table
            :headers="headers"
            :items="archivosCargados"
            :items-per-page="10"
            class="elevation-1"
            :key="archivosCargados.length"
        >
          <template #item="{ item }">
            <tr>
              <td>{{item.id}}</td>
              <td>{{ item.folio }}</td>
              <td>{{ item.etapa }}</td>
              <td>{{ item.nombre_archivo }}</td>
              <td>
                <v-btn
                    icon
                    @click="deleteFile(item.id,item.folio, item.etapa)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      id: '',
      folio: '',
      etapa: 'Selecciona',
      archivo: null,
      message: '',
      messageType: 'success',
      loading: false,
      isFormValid: false, // Estado de validación del formulario

      uploadProgress: 0,
      // Agregar una propiedad para el folio anterior
      previousFolio: null,

      etapas: [
        'Selecciona',
        'ALTA',
        'EN DIAGNÓSTICO',
        'PREPARANDO COTIZACIÓN',
        'COTIZACIÓN EN ESPERA DE AUTORIZACIÓN',
        'EN SERVICIO',
        'EN PROCESO DE EMBARQUE',
        'ENVIADO',
        'ENTREGADO'
      ],
      rules: {
        required: (value) => !!value || 'Este campo es requerido',
      },
      refreshKey: 0,
      archivosCargados: [], // Lista de archivos cargados
      headers: [
        { text: 'Identificador', value: 'id'},
        { text: 'Folio', value: 'nombre' },
        { text: 'Etapa', value: 'etapa' },
        { text: 'Archivo', value: 'fecha_carga' },
        { text: 'Acciones', value: 'acciones', sortable: false }
      ],
    };
  },
  methods: {
    async uploadFile() {

      // Validación manual de los campos
      const isFolioValid = this.rules.required(this.folio) === true;
      const isEtapaValid = this.rules.required(this.etapa) === true;
      const isArchivoValid = this.rules.required(this.archivo) === true;

      // Si algún campo no es válido, muestra un mensaje y retorna
      if (!isFolioValid || !isEtapaValid || !isArchivoValid) {
        this.message = 'Por favor llena todos los campos requeridos';
        this.messageType = 'error';
        return;
      }

      const formData = new FormData();
      formData.append('id', this.id);
      formData.append('folio', this.folio);
      formData.append('etapa', this.etapa);
      formData.append('archivo', this.archivo);

      try {
        this.loading = true;
        this.uploadProgress = 0;

        await axios.post(`${API_URL}/api/carga-archivos`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          onUploadProgress:(progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          }
        });
        await new Promise((resolve) => setTimeout(resolve, 500)); // 500ms de retraso

        await this.fetchUploadedFiles(); // Actualizar la lista de archivos cargados

        this.messageType = 'success';
        this.etapa = 'Selecciona';

        this.archivo = null;
      } catch (error) {
        console.error('Error en carga de archivos:', error);
        this.message = 'Error al subir archivo';
        this.messageType = 'error';
        this.loading = false;

      }
    },
    onFileChange(file) {
      if (!file) {
        this.archivo = null;
        this.message = 'Por favor selecciona un archivo válido';
        this.messageType = 'error';
        return;
      }

      // Asegúrate de que el archivo esté cargado completamente
      setTimeout(() => {
        this.archivo = file; // Confirma que el archivo está en v-model
        console.log('Archivo cargado:', this.archivo);
      }, 100); // Da un breve tiempo para garantizar que se procese correctamente
    },
    async fetchUploadedFiles() {
      // Verifica si el folio está vacío

      if (!this.folio) {
        this.archivosCargados = []; // Deja la tabla en blanco
        this.etapa = 'Selecciona'; // Reinicia la etapa si no hay folio
        return; // Sale de la función
      }
      //const timestamp = new Date().getTime(); // Genera un timestamp
      this.loading = true;
      try {

        const { data } = await axios.get(`${API_URL}/api/carga-archivos/consulta-etapa?folio=${this.folio}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          'Cache-Control': 'no-cache', // Agrega este encabezado
        });
        // Forzar reactividad creando un nuevo array
        this.archivosCargados = [...data];
        this.etapa = 'Selecciona'; // Reinicia la etapa
        //this.previousFolio = this.folio; // Actualiza el folio anterior

        this.messageType = 'success';
      } catch (error) {
        console.error('Error al obtener archivos cargados:', error);
        this.archivosCargados = [];

        this.messageType = 'error';
      } finally {
        this.loading = false;
      }
    },
    async deleteFile(id, folio, etapa) {
      try {
        await axios.delete(`${API_URL}/api/carga-archivos/borrar?id=${id}&folio=${folio}&etapa=${etapa}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.message = 'Archivo borrado correctamente';
        this.messageType = 'success';

        // Actualiza la lista de archivos cargados
        this.fetchUploadedFiles(); // Si quieres hacer una nueva llamada al servidor

        // O bien, elimina el archivo de la lista localmente
        this.archivosCargados = this.archivosCargados.filter(item => item.folio !== folio || item.etapa !== etapa);

        this.archivo = null;
        this.etapa = 'Selecciona';
        // Si quieres asegurarte de que la tabla esté vacía, puedes hacer
        if (this.archivosCargados.length === 0) {
          this.etapa = ''; // Limpiar etapa si no hay archivos
        }

      } catch (error) {
        console.error('Error al borrar archivo:', error);
      }
    },
    resetForm() {
      this.id = '';
      this.folio = '';
      this.etapa = 'Selecciona';
      this.archivo = null;
      this.message = '';
      this.isFormValid = false;
      if (this.$refs.form) {
        this.$refs.form.reset(); // Reinicia el formulario si está disponible
      }
    },
  },

};
</script>

<style scoped>
.v-card {
  margin: 0 auto;
}

.v-card-title {
  justify-content: center;
}
.custom-bg {
  background-color: #009496;
}
.v-btn {
  margin-left: 10px;
}
</style>
